@font-face {
    font-family: 'roboto';
    src: url('./assets/fonts/Roboto-Regular-webfont.eot');
    src: url('./assets/fonts/Roboto-Regular-webfont.eot?#iefix') format('embedded-opentype'), 
         url('./assets/fonts/roboto-regular-webfont.woff2') format('woff2'),
         url('./assets/fonts/roboto-regular-webfont.woff') format('woff'), 
         url('./assets/fonts/roboto-regular-webfont.ttf') format('truetype'), 
         url('./assets/fonts/roboto-regular-webfont.svg#robotoregular') format('svg');
}

@font-face {
    font-family: 'robotomedium';
    src: url('./assets/fonts/roboto-medium-webfont.eot');
    src: url('./assets/fonts/roboto-medium-webfont.eot?#iefix') format('embedded-opentype'), 
         url('./assets/fonts/roboto-medium-webfont.woff2') format('woff2'), 
         url('./assets/fonts/roboto-medium-webfont.woff') format('woff'), 
         url('./assets/fonts/roboto-medium-webfont.ttf') format('truetype'), 
         url('./assets/fonts/roboto-medium-webfont.svg#robotomedium') format('svg');
  }

  @font-face {
    font-family: 'robotobold';
    src: url('./assets/fonts/roboto-bold-webfont.eot');
    src: url('./assets/fonts/roboto-bold-webfont.eot?#iefix') format('embedded-opentype'), 
         url('./assets/fonts/roboto-bold-webfont.woff2') format('woff2'), 
         url('./assets/fonts/roboto-bold-webfont.woff') format('woff'), 
         url('./assets/fonts/roboto-bold-webfont.ttf') format('truetype'), 
         url('./assets/fonts/roboto-bold-webfont.svg#robotobold') format('svg');
  }

  @font-face {
    font-family: 'robotolight';
    src: url('./assets/fonts/roboto-light-webfont.eot');
    src: url('./assets/fonts/roboto-light-webfont.eot?#iefix') format('embedded-opentype'), 
         url('./assets/fonts/roboto-light-webfont.woff2') format('woff2'), 
         url('./assets/fonts/roboto-light-webfont.woff') format('woff'), 
         url('./assets/fonts/roboto-light-webfont.ttf') format('truetype'), 
         url('./assets/fonts/roboto-light-webfont.svg#robotolight') format('svg');
  }


html {
    height: 100%;
    font-size: 10px;
  }

html body {
    font-family: roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1.4rem;
    line-height: 1.42857143;
    color: #333333;
    background-color: #fff;
    margin: 0;
}

h1 {
    font-family: roboto, "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 3.6rem;
    font-weight: normal;
    color: #0073a8;
    margin-top: 1.6rem;
    margin-bottom: 3.2rem;
}

.header {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: url('./assets/navigation_bar_bg.png') no-repeat scroll;
    background-size: cover;
    z-index: 30;
    height: 60px;
}

.header .header-item {
    display : block;
    margin-left: auto;
    margin-right: auto;
}

.content {
    position: relative;
    padding-left: 2.4rem;
    padding-right: 2.4rem;
    padding-top: 2.4rem;
}

.input-text input {
    background: #f0f0f0;
    display: inline-block;
    padding-left: 5px;
    border: 1px solid;
    border-color: #e6e6e6;
}

.MuiInputBase-root input[aria-invalid="true"] {
    border-color: red !important;
}

.float-right {
    float: right
}

.top-margin-15 {
    margin-top: 1.5rem;
}

.language-menu {
    min-width: 10rem;
}

.language-menu img {
    width: 2rem;
    max-height: 2rem;
    padding-left: 1rem;
    float: right;
}

.language-menu span {
    display: inline-block;
    line-height: 2rem;
}

.language-menu span.selected {
    color: #fff;
}

.language-menu span.open {
    color: #0073a8;
}

.MuiListItem-root.Mui-selected {
    background-color: #fff !important;
}

.MuiTypography-colorTextSecondary {
    color: #fff !important;
}